export type SortableItem = {
  name: string;
  position?: number | null;
};

export type SortableItemWithDeletedOn = {
  deletedOn?: Date | null;
} & SortableItem;

export type Sortable = SortableItem | SortableItemWithDeletedOn;

export const positionNameSorter = (a: Sortable, b: Sortable): number => {
  const aDeleted = !!(a as SortableItemWithDeletedOn).deletedOn;
  const bDeleted = !!(b as SortableItemWithDeletedOn).deletedOn;

  if (aDeleted !== bDeleted) return aDeleted ? 1 : -1;

  const posA = a.position ?? 0;
  const posB = b.position ?? 0;
  if (posA !== posB) return posA - posB;

  return a.name.localeCompare(b.name);
};

export const nameSorter = (a: SortableItem, b: SortableItem) => {
  return a.name.localeCompare(b.name);
};

export const stringSorter = (a: string, b: string) => a.localeCompare(b);
export const numberSorter = (a: number, b: number) => a - b;
export const labelSorter = (a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label);

export const dateSorter = (a: { date: Date; id: number }, b: { date: Date; id: number }) => {
  // date ASC, id ASC
  const date = a.date.getTime() - b.date.getTime();
  if (date !== 0) return date;

  return a.id - b.id;
};

export const batchSorter = (
  a: { penDropIndex?: number | null; pen?: { position?: number | null; name?: string } | null },
  b: { penDropIndex?: number | null; pen?: { position?: number | null; name?: string } | null }
): number => {
  if (a.pen?.position !== b.pen?.position) return (a.pen?.position ?? 0) - (b.pen?.position ?? 0);
  if (a.pen?.name !== b.pen?.name) return (a.pen?.name ?? '')!.localeCompare(b.pen?.name! ?? '');
  return (a.penDropIndex ?? 0) - (b.penDropIndex ?? 0);
};

export const batchLoadsSorter = (a: { loadIndex?: number }, b: { loadIndex?: number }): number => {
  return (a.loadIndex ?? 0) - (b.loadIndex ?? 0);
};
